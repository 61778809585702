import React, { useEffect, useState } from "react";
import { exportPartnerConnections, fetchPartnerConnections, mapToData } from "../fn/api";
import { clog, is, isEmpty, valueOf } from "puppit-shared/fn";
import { sessionTokenAtom } from "../fn/atomic";
import { useAtom } from "jotai";
import { PARTNER_ID, PARTNER_NAME } from "../fn/dict";
import { ascending, distinctOn, getterFor } from "../fn/fn";
import { EMAIL, NAME } from "puppit-shared/dict";


const ManageConnections = () => {
    const [sessionToken,] = useAtom( sessionTokenAtom );
    const [processing, setProcessing] = useState( false );
    const [exporting, setExporting] = useState( false );
    const [data, setData] = useState( [] );

    useEffect( () => {

        const abort = new AbortController();

        setProcessing( true );
        fetchPartnerConnections( abort ).then( mapToData )
                                        .then( setData )
                                        .catch( error => clog( error ) ) //status
                                        .finally( () => setProcessing( false ) )

        return () => abort.abort();

    }, [sessionToken] );

    const doExport = () => {


        const abort = new AbortController();

        setExporting( true );
        exportPartnerConnections( abort ).then( mapToData )
                                         .then( str => {
                                             const csvContent = "data:text/csv;charset=utf-8," + str
                                             const encodedUri = encodeURI( csvContent );
                                             window.open( encodedUri, '_export' );
                                         } )
                                         .finally( () => setExporting( false ) )

        return () => abort.abort();
        ;


    }

    const partnerFactory = ( vo, idx ) => {

        const get = getterFor( vo );

        return (
            <li key={idx} className='list-group-item px-0 border-0'>
                <b style={{ fontSize: '1.5rem' }}>{get( PARTNER_NAME )}</b>
                <div className="card border-0">
                    <div className="card-body">
                        <table className="table table-borderless table-striped">
                            <tbody>
                            <tr>
                                <td><b>Name</b></td>
                                <td><b>Email</b></td>
                            </tr>

                            {data.filter( is( PARTNER_ID, get( PARTNER_ID ) ) )
                                 .map( ( v, idx ) => <tr key={"li-" + idx}>
                                     <td>{valueOf( NAME )( v )}</td>
                                     <td>{valueOf( EMAIL )( v )}</td>
                                 </tr> )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </li> )

    }

    return (
        <>

            <h1 className="text-center mb-3">Connections</h1>

            <div className="d-flex justify-content-end">
                <button type="button"
                        disabled={processing || exporting}
                        onClick={doExport}
                        className="btn btn-outline-success rounded">
                    Download <i className="bi bi-download"></i>
                </button>
            </div>


            <ul className="list-group list-group-flush">
                {data.filter( distinctOn( PARTNER_ID, [] ) )
                     .sort( ascending( PARTNER_NAME ) )
                     .map( partnerFactory )}

                <li className={'list-group-item px-0 border-0' + ( isEmpty( data ) ? '' : ' d-none ' )}>
                    <h3 className="text-muted">Nothing yet</h3>
                </li>
            </ul>
        </> )


}

export default ManageConnections;