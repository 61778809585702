import PartnerSelector from "./PartnerSelector";
import React, { useState } from "react";
import ZoneSelector from "./ZoneSelector";
import { INTERNAL_ID, ISSUE_QUESTION, ISSUE_SUMMARY, STATE, VOTE_ACCOUNT_CYPHER } from "../fn/dict";
import { Navigate, useLocation } from "react-router-dom";
import { has, isDefined, valueOf } from "puppit-shared/fn";
import { EMAIL } from "puppit-shared/dict";
import { publishPartnerVote } from "../fn/api";
import { deviceContextAtom, processingAtom, userAtom } from "../fn/atomic";
import { useAtom } from "jotai/index";
import { MANAGE_VOTES_PAGE } from "../fn/navigation-fn";
import { minusOne, plusOne } from "../fn/fn";


const PublishVoteFlow = () => {

    const location = useLocation()
    const state = valueOf( STATE )( location )
    const internalId = valueOf( INTERNAL_ID )( state )

    const [userProfile,] = useAtom( userAtom )
    const [blocvoteUserContext,] = useAtom( deviceContextAtom );
    const [processing, setProcessing] = useAtom( processingAtom )
    const [selectedPartner, setSelectedPartner] = useState( undefined )
    const [selectedZone, setSelectedZone] = useState( undefined );
    const [voteCloseTimestamp, setVoteCloseTimestamp] = useState( undefined );
    const [redirect, setRedirect] = useState( undefined );
    const [errorMessage, setErrorMessage] = useState( undefined );

    const formComplete = () => isDefined( selectedPartner ) && isDefined( selectedZone ) && isDefined( voteCloseTimestamp )
    const isPublishable = () => has( ISSUE_QUESTION )( state ) && has( ISSUE_SUMMARY )( state )

    const publish = () => {
        const email = valueOf( EMAIL )( userProfile );
        const userContext = valueOf( email )( blocvoteUserContext );
        const cypher = valueOf( VOTE_ACCOUNT_CYPHER )( userContext );

        const vo = {
            internalId: internalId,
            issueZone: selectedZone,
            issuePartner: selectedPartner,
            voteCloseTimestamp: voteCloseTimestamp + 'T23:59:59Z',
            voteAccountCypher: cypher,
        }

        setProcessing( plusOne );
        let errorOccurred = false;

        publishPartnerVote( vo )
            .catch( () => {
                errorOccurred = true;
            } )
            .finally( () => {
                setProcessing( minusOne )
                if ( errorOccurred )
                    setErrorMessage( "Something went wrong. Please report this issue to hello@blocvote.org and we'll fix it as soon as we can." );
                else
                    setRedirect( MANAGE_VOTES_PAGE )
            } )
    }

    const now = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = ( now.getMonth() + 1 ).toString().padStart( 2, "0" );
        const day = now.getDate().toString().padStart( 2, "0" );
        return year + "-" + month + "-" + day
    }


    if ( isDefined( redirect ) )
        return <Navigate to={redirect}/>


    if ( !isPublishable() )
        return (
            <>
                <h1 className="text-center mb-3">Manage Votes </h1>

                <div className="text-center mb-2">
                    <b style={{ fontSize: '1.7rem' }}>
                        Before you can publish this vote it must have a summary and a question.
                    </b>
                </div>

                <p className="text-center">Please go back and enter the details.</p>

                <button onClick={() => setRedirect( MANAGE_VOTES_PAGE )}
                        type="button"
                        className="btn btn-outline-dark btn-block rounded">
                    Back
                </button>
            </>
        )

    return (
        <>
            <h1 className="text-center mb-3">Manage Votes</h1>

            <div className="mb-3">
                <PartnerSelector message={'Who is publishing your vote?'}
                                 processing={processing}
                                 setProcessing={setProcessing}
                                 selectedPartner={selectedPartner}
                                 setSelectedPartner={setSelectedPartner}/>
            </div>

            <div className="mb-3">
                <ZoneSelector message={'Where would you like to publish your vote?'}
                              partnerId={selectedPartner}
                              processing={processing}
                              setProcessing={setProcessing}
                              selectedZone={selectedZone}
                              setSelectedZone={setSelectedZone}/>
            </div>

            <div className="mb-3">
                <b style={{ fontSize: '1.7rem' }}>Closing date</b>
                <p>Your vote will close at midnight on the date chosen.</p>
                <input disabled={processing}
                       onChange={event => setVoteCloseTimestamp( event.target.value )} className="form-control"
                       type="date"
                       min={now()}
                />

            </div>

            <div className="text-danger mb-2"
                 hidden={!isDefined( errorMessage )}>
                {errorMessage}
            </div>

            <div className="d-flex justify-content-between mt-5">

                <button onClick={() => setRedirect( MANAGE_VOTES_PAGE )}
                        disabled={processing}
                        type="button"
                        className="btn btn-outline-dark  rounded">
                    Back
                </button>

                <button className="btn btn-outline-success  rounded"
                        disabled={!formComplete() || processing}
                        onClick={publish}>
                    Publish
                </button>
            </div>


        </>
    )
}

export default PublishVoteFlow