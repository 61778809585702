import NavBottom from "./NavBottom";
import NavTop from "./NavTop";

const Layout = props => {

    return (
        <div className="container body-content">

            <main>
                <NavTop/>
                {props.children}
                <NavBottom/>
            </main>

        </div>
    );
}

export default Layout;
