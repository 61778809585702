import { EMAIL, NAME } from "puppit-shared/dict";
import { fieldIsNotBlank, setterFor } from "./fn";
import { coalesce, has, hasNot, isDefined, valueOf, valueOfOr } from "puppit-shared/fn";
import { COUNTRY_CODE, PROFILE_VERIFIED, REJECTION_REASON, ROLES, VOTE_ACCOUNT_CYPHER, ZIP_CODE } from "./dict";
import { coalesceTrilean } from "./tnew";

export const setUserEmail = setterFor( EMAIL )
export const setProfileVerified = setterFor( PROFILE_VERIFIED )
export const userDetailsAreComplete =
    user => isDefined( user ) &&
        fieldIsNotBlank( NAME )( user ) &&
        fieldIsNotBlank( COUNTRY_CODE )( user ) &&
        fieldIsNotBlank( ZIP_CODE )( user );

export const userDetailsAreNotComplete = user => !userDetailsAreComplete( user )

export const accountVerificationStatus = user => coalesceTrilean( valueOf( PROFILE_VERIFIED )( user ) )

export const hasRole = ( vo, role ) => {
    const roles = valueOfOr( ROLES, "" )( vo )
    return roles.includes( role )
}

export const extractVoteAccountCypher = ( user, deviceContext ) => {
    const email = valueOf( EMAIL )( user );
    return coalesce( deviceContext[ email ], {} )[ VOTE_ACCOUNT_CYPHER ];
}

export const previousVerificationWasRejected = has( REJECTION_REASON )
export const previousVerificationWasNotRejected = hasNot( REJECTION_REASON )