import React from "react";
import { valueOf } from "puppit-shared/fn";

export const ICON = "icon";
export const HEADING = "heading";
export const LEAD = "lead";


const PageHeading = props => {


    return <div className="pt-2 pb-4">

        <h2>{valueOf( HEADING )( props )}</h2>
        <div className="fade-text text-muted ">{valueOf( LEAD )( props )}</div>
    </div>

}

export default PageHeading
